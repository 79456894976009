<template>
  <div>
    <div class="data_wrapper">
      <el-row :gutter="12">
        <el-col :span="6">
          <el-card>
            <div class="record_data">
              <p class="money">{{ statisticsData.totalActualPayMoney }}</p>
              <p class="title">普通收款总收入</p>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card>
            <div class="record_data">
              <p class="money">{{ statisticsData.totalBusinessSharingMoney }}</p>
              <p class="title">商家总收入</p>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card>
            <div class="record_data">
              <p class="money">{{ statisticsData.totalPlatformSharingMoney }}</p>
              <p class="title">平台总收入</p>
            </div>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card>
            <div class="record_data">
              <p class="money strong_tip">{{ statisticsData.totalWithdraw }}</p>
              <p class="title">已提现金额</p>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="8">
          <span class="searchText">商家昵称</span>
          <el-input
            class="searchInput"
            placeholder="商家昵称"
            @keyup.enter.native="getList"
            v-model.trim="searchValue.businessName"
            clearable
          >
          </el-input>
        </el-col>

        <el-col :span="8">
          <span class="searchText">商家ID</span>
          <el-input
            class="searchInput"
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder="商家ID"
            @keyup.enter.native="getList"
            v-model="searchValue.businessId"
            clearable
          >
          </el-input>
        </el-col>

        <!-- <el-form inline> -->
        <!-- <bm-areaSelect v-model="searchValue.areaId"></bm-areaSelect> -->
        <!-- </el-form> -->
      </el-row>
      <el-row class="query">
        <el-col :span="16">
          <span class="searchText">时间</span>
          <el-date-picker
            value-format="timestamp"
            v-model="date"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="8">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>数据列表</span>
      </div>
      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="businessId" label="商家ID"> </el-table-column>
        <el-table-column label="商家信息" align="center">
          <template slot-scope="scope">
            <div class="product-info">
              <img class="avatar" :src="scope.row.businessLogo" />
              <span class="title"> {{ scope.row.businessName }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="amount" label="申请提现金额（元）"> </el-table-column>
        <el-table-column align="center" prop="verifyStatus" label="提现状态">
          <template> 待审核 </template>
        </el-table-column>
        <el-table-column align="center" prop="storeNames" label="申请门店"> </el-table-column>
        <el-table-column align="center" prop="operatorMemberName" label="申请人员"> </el-table-column>
        <el-table-column align="center" prop="currentTotalAmount" label="申请时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | formatTime }}
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="approveWithdraw(scope.row)" type="text" size="small"> 通过</el-button>
            <el-button @click="showReject(scope.row)" type="text" size="small"> 拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
        <div></div>
      </div>
    </el-card>

    <el-dialog :close-on-click-modal="false" title="同意提现" :visible.sync="approve.show" width="700px">
      <div class="paymentType">
        <div class="item">
          <el-radio v-model="approve.paymentType" :disabled="approve.detail.collectionType == 1" :label="1"
            >线上结算</el-radio
          >
          <div>
            1.如果付款到账时效为1-3日，最快次日到账<br />
            2.真实结算状态以微信商户付款状态为准，平台线上结算目前只提交到微信成功，不代表真正付款成功，如果微信商户付款失败，则还需要线下付款给商户
          </div>
        </div>
        <div class="item">
          <el-radio v-model="approve.paymentType" :disabled="approve.detail.collectionType == 0" :label="2"
            >线下结算</el-radio
          >
          <div>即平台在同意申请后，把状态改为已结算。但仍需要人工转账，不会从商户号转钱。</div>
        </div>
      </div>

      <el-card class="detail">
        <div class="vendor">
          <div class="item">
            <div>商家:{{ approve.detail.businessName }}</div>
            <div class="store">提现门店: {{ approve.detail.storeNames }}</div>
          </div>
          <div class="next">
            <div>申请人: {{ approve.detail.operatorMemberName }}</div>
            <div class="time">申请时间: {{ approve.detail.createTime | formatTime }}</div>
          </div>
        </div>
        <div class="withdrawMoney">
          <div class="money" v-if="approve.detail.collectionType == 1">
            <!-- <div>开户名:{{ approve.detail.bankCode }}</div> -->
            <div>开户行:{{ approve.detail.bankName }}</div>

            <div>银行卡号:{{ approve.detail.encBankNo }}</div>
          </div>
          <div class="money">提现金额:{{ approve.detail.amount }}</div>
        </div>
        <div class="collection">
          <div class="item">
            <div>收款人:{{ approve.detail.payeeName }}</div>
          </div>
          <div class="next">
            <div>手机号: {{ approve.detail.payeeMobile }}</div>
          </div>
        </div>
      </el-card>

      <span slot="footer" class="dialog-footer">
        <el-button @click="approve.show = false">取 消</el-button>
        <el-button type="primary" @click="confirmApproveWithdraw">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 输入拒绝原因 -->

    <el-dialog :close-on-click-modal="false" title="拒绝原因" :visible.sync="rejectDialog" width="350px">
      <el-input
        type="textarea"
        maxlength="30"
        show-word-limit
        placeholder="请输入拒绝原因"
        v-model.trim="rejectReason"
        clearable
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectDialog = false">取 消</el-button>
        <el-button type="primary" @click="rejectWithdraw">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { pagination } from '@/mixin/pagination'
export default {
  mixins: [pagination],
  data() {
    return {
      rejectReason: '',
      date: [],
      searchValue: {
        businessId: '',
        businessName: '',
        startTime: '',
        endTime: '',
        verifyStatus: 0
      },
      list: [],
      rejectDialog: false,
      rejectReasonText: '',
      memberId: '',
      approve: { show: false, paymentType: 2, verifyStatus: 1, paymentId: 1, detail: [] },
      statisticsData: {
        totalActualPayMoney: 0,
        totalBusinessSharingMoney: 0,
        totalPlatformSharingMoney: 0,
        totalWithdraw: 0
      }
    }
  },

  methods: {
    getAggregate() {
      this.loading = true
      this.$api.financial
        .financial('getAggregate', {
          params: {
            ...this.searchValue,
            startTime: this.date ? this.date[0] : '',
            endTime: this.date ? this.date[1] : ''
          }
        })
        .then(res => {
          this.statisticsData = res
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    approveWithdraw(row) {
      this.approve.detail = { ...row }

      this.approve.paymentType = row.collectionType == 1 ? 2 : 1
      this.approve.show = true
      this.approve.paymentId = row.id
    },
    // 请求后端获取提现审核列表
    getList(val) {
      this.getAggregate()
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      let params = {
        params: {
          ...this.searchValue,
          startTime: this.date ? this.date[0] : '',
          endTime: this.date ? this.date[1] : '',
          page: this.currentPage,
          size: this.pageSize
        }
      }
      this.$http
        .get('/boom-center-statistics-service/sysAdmin/finance/payment', params)

        .then(res => {
          this.list = res.records
          this.total = parseInt(res.total)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          this.loading = false
        })
    },

    confirmApproveWithdraw(row) {
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.$http
        .post('/boom-center-order-service/sysAdmin/payment/audit', {
          denialNotes: '',
          paymentId: this.approve.paymentId,
          paymentType: this.approve.paymentType,
          verifyStatus: this.approve.verifyStatus
        })

        .then(res => {
          this.getList()
          this.approve.show = false
          this.$message.success('审核通过')
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },

    showReject(row) {
      this.rejectDialog = true
      this.memberId = row.id
      this.rejectReason = ''
    },
    rejectWithdraw() {
      if (this.rejectReason === '') {
        this.$message.error('请输入拒绝原因')
        return
      }
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http
        .post('/boom-center-order-service/sysAdmin/payment/audit', {
          denialNotes: this.rejectReason,
          paymentId: this.memberId,
          paymentType: 1,
          verifyStatus: 2
        })
        .then(res => {
          this.getList()
          this.rejectReason = ''
          this.rejectDialog = false
          this.$message.success('您已拒绝提现')
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },

    resetSearch() {
      this.searchValue = {
        businessId: '',
        businessName: '',
        startTime: '',
        endTime: '',
        verifyStatus: 0
      }
      this.date = []
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.query {
  margin-top: 20px;
}
.data_wrapper {
  margin: 20px;
}
.record_data {
  text-align: center;
  .money {
    font-size: 22px;
  }
  .title {
    font-size: 16px;
  }
  .strong_tip {
    color: #e6a23c;
  }
}

.tableContainer .footer {
  justify-content: center;
}

.product-info {
  display: flex;
  align-items: center;
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    vertical-align: middle;
  }
  .title {
    vertical-align: middle;
  }
}

.paymentType {
  display: flex;
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 330px;
    margin: 20px;
    padding: 10px;
    border-radius: 18px;
    box-shadow: 0 2px 6px 0 #999;
  }
}
.detail {
  background-color: #99999930;
  .vendor {
    display: flex;
    justify-content: space-between;
    .item {
      .store {
        margin-top: 10px;
      }
    }
    .next {
      width: 210px;
      .time {
        margin-top: 10px;
      }
    }
  }
  .withdrawMoney {
    display: flex;
    margin: 20px 0;
    justify-content: space-between;
    .money {
      width: 210px;
    }
  }

  .collection {
    display: flex;
    justify-content: space-between;
    .next {
      width: 210px;
    }
  }
}
</style>
